import { AgdirFormsModule } from '@agdir/agdir-forms';
import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirGoogleEnvironment, AgdirMapboxEnvironment } from '@agdir/environment/domain';
import { FILE_MANAGER_PREFIX } from '@agdir/fillagring/angular';
import { SUPPORTED_LANGUAGES, TranslocoHttpLoader } from '@agdir/i18n/angular';
import { AgdirNavigationItem, NavigationService } from '@agdir/layout';
import { ApiModule } from '@agdir/services';
import { ApplicationConfig, importProvidersFrom, Injectable, LOCALE_ID } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withEnabledBlockingInitialNavigation,
	withInMemoryScrolling,
	withPreloading,
	withRouterConfig,
} from '@angular/router';
import { provideTransloco } from '@ngneat/transloco';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { en_GB, NZ_I18N, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const ITEMS: AgdirNavigationItem[] = [
	{
		link: 'dashboard',
		title: 'Dashboard',
		type: 'basic',
		icon: 'dashboard',
	},
	{
		link: 'inventory',
		title: 'Inventory',
		type: 'basic',
		icon: 'inventory',
	},
	{
		link: 'companies',
		title: 'Companies',
		type: 'basic',
		icon: 'cases',
	},
	{
		link: 'help-management',
		title: 'Help management',
		type: 'basic',
		icon: 'help',
	},
	{
		link: 'push-notification',
		title: 'Push notification',
		type: 'basic',
		icon: 'agdir_bell',
	},
	{
		link: 'feature-toggle',
		title: 'Feature toggle',
		type: 'basic',
		icon: 'visibility_off',
	},
	{
		link: 'coming-soon',
		title: 'Coming soon',
		type: 'basic',
		icon: 'quickreply',
	},
	{
		link: 'i18n',
		title: 'I18n',
		type: 'basic',
		icon: 'language',
	},
	{
		link: 'classifiers',
		title: 'Classifiers',
		type: 'basic',
		icon: 'database',
	},
	{
		link: 'chart-groups',
		title: 'Chart groups',
		type: 'basic',
		icon: 'chart_data',
	},
	{
		link: 'create-notification',
		title: 'Send notification',
		type: 'basic',
		icon: 'notification_important',
	},
];

@Injectable()
class AdminNavigationService extends NavigationService {
	protected override initNavigation(): Observable<AgdirNavigationItem[]> {
		return of(ITEMS);
	}
}

export const appConfig: ApplicationConfig = {
	providers: [
		{
			provide: NZ_I18N,
			useValue: en_GB,
		},
		{ provide: ENVIRONMENT, useValue: environment },
		{ provide: LOCALE_ID, useValue: 'no-NO' },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.get(AgdirGoogleEnvironment).reCaptchaV3SiteKey,
		},
		{ provide: FILE_MANAGER_PREFIX, useValue: '/[a-zA-Z0-9]{24}/file-manager' },
		{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
		{ provide: NavigationService, useClass: AdminNavigationService },
		provideTransloco({
			config: {
				availableLangs: [...SUPPORTED_LANGUAGES.keys()],
				fallbackLang: 'en',
				defaultLang: 'en',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: true,
				// TODO: do not use, can cause issues on classifiers translations
				// missingHandler: {
				// 	useFallbackTranslation: true,
				// },
			},
			loader: TranslocoHttpLoader,
		}),
		provideNzI18n(en_GB),
		importProvidersFrom(ApiModule.forRoot(), NgxMapboxGLModule.withConfig(environment.get(AgdirMapboxEnvironment)), AgdirFormsModule),
		provideRouter(
			appRoutes,
			withEnabledBlockingInitialNavigation(),
			withPreloading(PreloadAllModules),
			withInMemoryScrolling({
				// scrollPositionRestoration: 'enabled'
			}),
			withRouterConfig({
				onSameUrlNavigation: 'reload',
				paramsInheritanceStrategy: 'always',
			}),
		),
		provideAnimations(),
	],
};
